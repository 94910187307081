import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarFilterButton,
  GridToolbarQuickFilter,
} from '@mui/x-data-grid';
import React, { Dispatch, SetStateAction } from 'react';
import { CollectionPayload, CollectionTypes } from '@src/types/Collection';
import GenerateDevicesButton from './Devices/GenerateDevicesButton';
import collectionSx from './configs/CollectionSx';

interface ToolbarProps {
  collectionType?: CollectionTypes;
  setOpen: Dispatch<SetStateAction<boolean>>;
  setJsonPayload: Dispatch<SetStateAction<CollectionPayload | null>>;
}

function CollectionToolbar({
  collectionType,
  setOpen,
  setJsonPayload,
}: ToolbarProps): React.ReactElement {
  return (
    <GridToolbarContainer sx={collectionSx.collectionToolbar}>
      <div>
        {collectionType === CollectionTypes.DEVICES && (
          <GenerateDevicesButton
            setJsonPayload={setJsonPayload}
            setOpen={setOpen}
          />
        )}
      </div>

      <div>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarExport />
        <GridToolbarQuickFilter
          sx={collectionSx.collectionToolbar.quickFilter}
        />
      </div>
    </GridToolbarContainer>
  );
}

export default CollectionToolbar;
