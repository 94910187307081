import { generateMqttClient } from '@pages/_loader/initMqttClient.loader';
import { isActionRole } from '@src/services/auth';
import { useWsSubscribe } from '@store/actionCreators/mqtt.js';
import React, { useEffect, useState } from 'react';
import './UnitTable.scoped.scss';
import UnitTableItem from '../UnitTableItem/UnitTableItem';
import Spinner from '@components/_elements/Spinner/Spinner';
import storeConnector from '@store/storeConnector';
import useIsSunSystemView from '../../../hooks/useIsSunSystemView.ts';

const UnitTable = ({
  currentUnitDeviceId,
  siteMeta,
  actions,
  titleOverride,
  role,
}) => {
  const isSunSystemView = useIsSunSystemView();
  const unitFieldsMap = {
    AllowedMaxP: null,
    AllowedMaxQ: null,
    AllowedMinP: null,
    AllowedMinQ: null,
    InvStatus: null,
    kVAR: null,
    kW: null,
    racks_online: null,
    RunMode: null,
    SOC: null,
    PCS_P: null,
    PCS_Q: null,
  };

  const [loading, setLoading] = useState(false);
  const [tableFirstRowsCount, setTableFirstRowsCount] = useState(null);
  const [activeHintSN, setActiveHintSN] = useState(null);

  const initialUnitsData = siteMeta.Units.reduce(
    (acc, o) => ({ ...acc, ...{ [o]: unitFieldsMap } }),
    {},
  );
  const [unitsData, setUnitsData] = useState(initialUnitsData);

  const [useIsNoCommsMqttClient, setUseIsNoCommsMqttClient] = useState();

  useEffect(() => {
    const startClient = async () => {
      const client = await generateMqttClient();
      client.setMaxListeners(100);
      setUseIsNoCommsMqttClient(client);
    };
    startClient();
    return () => {
      useIsNoCommsMqttClient?.end();
    };
    // eslint-disable-next-line
  }, []);

  useWsSubscribe({
    unit: {
      sns: siteMeta.Units,
      fields: Object.keys(unitFieldsMap),
      cb: (data, sn) => {
        setUnitsData((prev) => ({
          ...prev,
          ...{ [sn]: { ...prev[sn], ...data } },
        }));
      },
    },
  });

  useEffect(() => {
    setLoading(true);
    if (!currentUnitDeviceId && siteMeta?.Units?.length) {
      actions.setActiveUnitSN(siteMeta.Units[0]);
      actions.setActiveUnitName(siteMeta.UnitNames[0]);
    }
    setTables();
    setLoading(false);
  }, []);

  const setTables = () => {
    const tableLength = siteMeta.Units.length;
    const maxRowsNotScrolled = 16;
    if (tableLength < maxRowsNotScrolled) {
      if (tableFirstRowsCount !== tableLength) {
        setTableFirstRowsCount(tableLength);
      }
      return;
    }
    if (Math.ceil(tableLength / 2) > maxRowsNotScrolled) {
      if (tableFirstRowsCount !== Math.ceil(tableLength / 2)) {
        setTableFirstRowsCount(Math.ceil(tableLength / 2));
      }
      return;
    }
    if (tableFirstRowsCount !== maxRowsNotScrolled) {
      setTableFirstRowsCount(maxRowsNotScrolled);
    }
  };

  return (
    <>
      {
        <div className='block-container cell'>
          <div className='title-line'>
            {(titleOverride ? titleOverride : 'Unit') + ' Table'}
          </div>
          <div
            className={`unit-table-container ${
              !loading && tableFirstRowsCount && 'with-top-border'
            }`}
            id='unit-table-container'
          >
            {!loading &&
              tableFirstRowsCount &&
              [
                ...Array(siteMeta.Units.length <= tableFirstRowsCount ? 1 : 2),
              ].map((_v, tableColIdx) => (
                <table className='unit-table' key={tableColIdx}>
                  <thead>
                    <tr>
                      <th />
                      {isActionRole(role) &&
                      (siteMeta.ui.Command_Access_Enable ||
                        !process.env.VITE_READ_ONLY_MODE) ? (
                        <th />
                      ) : null}
                      {isActionRole(role) && siteMeta.ui.Notes_Enable ? (
                        <th />
                      ) : null}
                      <th className='max-w'>Mode</th>
                      <th className='max-w'>kW</th>
                      <th className='max-w'>kVAR</th>
                      {!isSunSystemView && (
                        <>
                          <th className='max-w'>SOC</th>
                          <th className='max-w'>Racks</th>
                        </>
                      )}
                    </tr>
                  </thead>
                  <tbody>
                    {siteMeta.Units.slice(
                      tableColIdx === 0 ? 0 : tableFirstRowsCount,
                      tableColIdx === 0
                        ? tableFirstRowsCount
                        : siteMeta.Units.length,
                    ).map((unitSn, idx) =>
                      unitsData[unitSn] ? (
                        <UnitTableItem
                          key={unitSn}
                          selected={unitSn === currentUnitDeviceId}
                          unitName={siteMeta.UnitNames[idx]}
                          unitSN={unitSn}
                          index={
                            tableColIdx === 0 ? idx : idx + tableFirstRowsCount
                          }
                          unitsData={unitsData[unitSn]}
                          activeHintSN={activeHintSN}
                          setActiveHintSN={setActiveHintSN}
                          useIsNoCommsMqttClient={useIsNoCommsMqttClient}
                        />
                      ) : null,
                    )}
                  </tbody>
                </table>
              ))}
          </div>
          {loading && <Spinner cover='container' />}
        </div>
      }
    </>
  );
};

export default storeConnector(UnitTable, {
  service: ['currentUnitDeviceId'],
  config: ['siteMeta'],
  user: ['role'],
});
