import Spinner from '@components/_elements/Spinner/Spinner';
import { isActionRole } from '@src/services/auth';
import { UserRole } from '@src/services/auth/roles';
import { SiteMeta } from '@src/types/SiteMeta';
import { WidgetTypeDeviceList } from '@src/types/Widget';
import storeConnector from '@store/storeConnector';
import React, { useMemo, useState } from 'react';
import TSCCommandsModal from '@components/Battery/Tesla/Commands/TSC/TSCCommandsModal';
import useSubscription from '@hooks/useSubscription';
import DeviceDataTable from '@components/_shared/DeviceDataTable';
import { useCurrentTscIdContext } from '@hooks/useCurrentTscId';
import UIBox from '@components/_shared/UIBox';
import useGetWidgetConfig from '@src/hooks/api/queries/useGetWidgetConfig';

interface ListContentProps {
  siteMeta: SiteMeta;
  role: UserRole;
  openCmdModal: (id: string) => void;
}

function ListContent({ siteMeta, openCmdModal, role }: ListContentProps) {
  const { currentTscId, setCurrentTscId } = useCurrentTscIdContext();
  const deviceNameDictionary = useMemo(
    () =>
      siteMeta.TeslaSiteControllers.map((tscId, i) => ({
        id: tscId,
        name: siteMeta.TeslaSiteControllerNames[i],
      })),
    [siteMeta],
  );

  const { data: widgetConfig, isLoading } =
    useGetWidgetConfig<WidgetTypeDeviceList>('tscList', {
      sourceDeviceId: currentTscId,
    });

  const dataPointsWithValues = useSubscription(widgetConfig?.dataPoints || []);
  return isLoading ? (
    <Spinner type='content-small' />
  ) : (
    <DeviceDataTable
      enableCmdBtnColumn={
        (siteMeta.ui.Command_Access_Enable ||
          !process.env.VITE_READ_ONLY_MODE) &&
        isActionRole(role)
      }
      hideFooter
      data={dataPointsWithValues}
      openCmdModal={openCmdModal}
      rowSelectionModel={[currentTscId]}
      onRowSelectionModelChange={([newTscId]: string) => {
        if (newTscId) setCurrentTscId(newTscId);
      }}
      deviceNameDictionary={deviceNameDictionary}
      getRowClassName={() => 'MuiDataGrid-clickable'}
    />
  );
}

function TeslaControllerList({
  siteMeta,
  role,
}: {
  siteMeta: SiteMeta;
  role: UserRole;
}) {
  const [cmdModal, setCmdModal] = useState(false);
  const [deviceIdForCmdModal, setDeviceIdForCmdModal] = useState<string>('');

  const tscNameForCmdModal = useMemo(
    () =>
      siteMeta.TeslaSiteControllerNames[
        siteMeta.TeslaSiteControllers.indexOf(deviceIdForCmdModal)
      ],
    [siteMeta, deviceIdForCmdModal],
  );

  const closeCmdModal = () => {
    setCmdModal(false);
  };

  return (
    <UIBox header='Tesla Controller List'>
      <ListContent
        role={role}
        siteMeta={siteMeta}
        openCmdModal={(deviceId: string) => {
          setDeviceIdForCmdModal(deviceId);
          setCmdModal(true);
        }}
      />
      <TSCCommandsModal
        unitName={tscNameForCmdModal}
        deviceId={deviceIdForCmdModal}
        open={cmdModal}
        onClose={() => closeCmdModal()}
      />
    </UIBox>
  );
}

export default storeConnector(TeslaControllerList, {
  config: ['siteMeta'],
  user: ['role'],
});
