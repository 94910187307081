import React, { ReactElement } from 'react';
import { SavedSearch } from '@types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import storeConnector from '@store/storeConnector';
import Input from '@components/_elements/Input/Input';
import Button from '@components/_elements/Button/Button';
import { Actions } from '@src/types/Actions';

interface EditFormProps {
  searchOptions: string[];
  activeSearch: SavedSearch;
  searchUnderEdit: SavedSearch;
  setSearchUnderEdit: React.Dispatch<SavedSearch | null>;
  setSearchField: (field: string, value: string) => void;
  setActiveSearch: React.Dispatch<SavedSearch>;
  actions: Actions;
}
function EditForm(props: EditFormProps): ReactElement {
  const {
    actions,
    searchOptions,
    activeSearch,
    searchUnderEdit,
    setSearchUnderEdit,
    setSearchField,
    setActiveSearch,
  } = props;
  let error = '';
  if (
    searchOptions.includes(activeSearch.name) &&
    activeSearch.name !== searchUnderEdit.name
  ) {
    error = 'This name is already in use';
  }
  if (!activeSearch.name) {
    error = 'Search name cannot be empty';
  }
  // todo: clean up this logic
  const disabled =
    !activeSearch.name ||
    (searchOptions.includes(activeSearch.name) &&
      activeSearch.name !== searchUnderEdit.name);

  const saveEditedSearch = async () => {
    setActiveSearch(await actions.saveEditedHistorianSearch(activeSearch));
  };

  return (
    <>
      <div className='input-search-name'>
        <Input
          error={error}
          type='text'
          value={activeSearch.name}
          onChange={(val: string): void => setSearchField('name', val)}
          placeholder='New search name'
        />
      </div>
      <>
        <Button
          title='Cancel'
          classType='container-bg'
          size='s'
          onClick={(): void => {
            setActiveSearch(searchUnderEdit);
            setSearchUnderEdit(null);
          }}
        >
          <FontAwesomeIcon icon={faTimes} />
        </Button>
        <Button
          title='Save'
          classType='container-bg'
          size='s'
          disabled={disabled}
          onClick={saveEditedSearch}
        >
          <FontAwesomeIcon icon={faCheck} />
        </Button>
      </>
    </>
  );
}

export default storeConnector(EditForm, {});
