import Spinner from '@components/_elements/Spinner/Spinner';
import React, { ReactElement } from 'react';
import { DataPointWithValue } from '@src/types/DataPoint';
import useSubscription from '@hooks/useSubscription';
import useDataPointsSearch from '@hooks/api/queries/useDataPointsSearch';
import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import Breaker from '@src/sld/shared/Breaker/Breaker';
import { DynamicConnector, Resistor } from '../shared/Shared';

// eslint-disable-next-line max-lines-per-function
function SuperstitionDiagram(): ReactElement {
  const { data: dataPoints, isLoading } = useDataPointsSearch(
    'superstition-sld',
    [
      { category: 'rtac', pointName: '891_status', sourceDeviceId: 'rtac_1' },
      { category: 'rtac', pointName: '52h1_status', sourceDeviceId: 'rtac_1' },
      { category: 'rtac', pointName: '52f1_status', sourceDeviceId: 'rtac_1' },
      { category: 'rtac', pointName: '52f2_status', sourceDeviceId: 'rtac_1' },
    ],
  );

  const dataPointsWithValue: DataPointWithValue[] = useSubscription(
    dataPoints || [],
  );

  const showOpenOrCloseSwitch = (
    switchValue: string | number | null | undefined,
    label: string,
  ): ReactJSXElement => {
    const openSwitch = <path d='m0 761.34 9.77 19.41V792' className='st7' />;
    const closedSwitch = <path d='M9.50 792v-0-.0v-20' className='st3' />;

    return (
      <>
        <text x={37} y={771} fill='currentColor' stroke='none'>
          {label}
        </text>
        {switchValue !== 0 ? closedSwitch : openSwitch}
      </>
    );
  };

  const adjustOpenOrCloseSwitchColor = (
    switchValue: string | number | null | undefined,
  ): string => (switchValue !== 0 ? 'red' : 'green');

  let switchDataPointValue: string | number | null | undefined;
  let breaker_1_dataPointValue: string | number | null | undefined;
  let breaker_2_dataPointValue: string | number | null | undefined;
  let breaker_3_dataPointValue: string | number | null | undefined;

  if (dataPointsWithValue !== null) {
    dataPointsWithValue.forEach((dataPoint: DataPointWithValue): void => {
      const { pointName, value } = dataPoint;

      switch (pointName) {
        case '891_status':
          switchDataPointValue = value;
          break;
        case '52h1_status':
          breaker_1_dataPointValue = value;
          break;
        case '52f1_status':
          breaker_2_dataPointValue = value;
          break;
        case '52f2_status':
          breaker_3_dataPointValue = value;
          break;
        default:
          break;
      }
    });
  }

  return isLoading ? (
    <Spinner cover='container' />
  ) : (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      xlinkHref='http://www.w3.org/1999/xlink'
      viewBox='125 25 1300 645'
      xmlSpace='preserve'
      colorInterpolationFilters='sRGB'
      className='st10'
      style={{ fill: 'none', stroke: 'currentColor' }}
    >
      <g>
        <title>Superstition Single Line Drawing</title>
        <g transform='rotate(90 512.586 810.225)'>
          <Resistor
            label='XFMR T1'
            xCoordinate={15}
            yCoordinate={740}
            path_1_coordinates='M33.36 758.64h-8.34a4.17 4.17-180 0 0-4.17 4.17 4.17 4.17-180 0 0 4.17 4.17 4.17 4.17-180 0 0-4.17 4.17 4.17 4.17-180 0 0 4.17 4.17 4.17 4.17-180 0 0-4.17 4.17 4.17 4.17-180 0 0 4.17 4.17 4.17 4.17-180 0 0-4.17 4.17 4.17 4.17-180 0 0 4.17 4.17h8.34'
            path_2_coordinates='M0 758.64h8.34a4.17 4.17 0 0 1 4.17 4.17 4.17 4.17 0 0 1-4.17 4.17 4.17 4.17 0 0 1 4.17 4.17 4.17 4.17 0 0 1-4.17 4.17 4.17 4.17 0 0 1 4.17 4.17 4.17 4.17 0 0 1-4.17 4.17 4.17 4.17 0 0 1 4.17 4.17A4.17 4.17 0 0 1 8.34 792H0'
          />
        </g>

        <g transform='translate(529.01 -617.309)'>
          <g transform='translate(8.937)'>
            {/* switch */}
            <path
              d='M9.77 761.33a4.5 4.5 0 1 1-4.5 4.5c0-2.48 2.02-4.5 4.5-4.5Zm0 2.25c-1.24 0-2.25 1.01-2.25 2.25s1.01 2.25 2.25 2.25 2.25-1.01 2.25-2.25-1.01-2.25-2.25-2.25Z'
              className='st6'
              fill={adjustOpenOrCloseSwitchColor(switchDataPointValue)}
            />

            <path d='M9.77 754.54v6.79' className='st7' />

            {/* display open switch or closed switch   */}
            {showOpenOrCloseSwitch(switchDataPointValue, '89-1')}

            <path d='M14.31 761.29h-9' className='st7' />
          </g>
        </g>

        <g transform='translate(533.952 -544.99)'>
          <Breaker
            status={breaker_1_dataPointValue}
            enableBreakerCommands
            label='52 H1'
            xCoordinate={60}
            yCoordinate={785}
          />
        </g>

        <g transform='translate(741.125 -349.047)'>
          <Breaker
            status={breaker_2_dataPointValue}
            enableBreakerCommands
            label='52 F1'
            xCoordinate={15}
            yCoordinate={740}
          />
        </g>

        <g transform='translate(741.125 -243.94)'>
          <Breaker
            status={breaker_3_dataPointValue}
            enableBreakerCommands
            label='52 F2'
            xCoordinate={15}
            yCoordinate={740}
          />
        </g>

        <g transform='translate(768.198 -362.583)'>
          <polygon
            transform='translate(146, 788) rotate(-0 0 0) scale(1.5)'
            points='0,5 0,0 5,2.5'
            stroke='white'
            fill='white'
          />
          <DynamicConnector
            label='34.5KV BESS FEEDER #1'
            pathCoordinates='M0 792h143.64'
            xCoordinate={248}
            yCoordinate={793}
            className='st7'
          />
        </g>

        <g transform='translate(837 -315)'>
          <polygon
            transform='translate(78, 788) rotate(-0 0 0) scale(1.5)'
            points='0,5 0,0 5,2.5'
            stroke='white'
            fill='white'
          />
          <DynamicConnector
            label='34.5KV BESS FEEDER #2'
            pathCoordinates='M0 744.42V792h75.28'
            xCoordinate={180}
            yCoordinate={795}
            className='st7'
          />
        </g>

        <g transform='translate(768.198 -257.477)'>
          <polygon
            transform='translate(146, 788) rotate(-0 0 0) scale(1.5)'
            points='0,5 0,0 5,2.5'
            stroke='white'
            fill='white'
          />
          <DynamicConnector
            label='34.5KV BESS FEEDER #3'
            pathCoordinates='M0 792h143.64'
            xCoordinate={250}
            yCoordinate={795}
            className='st7'
          />
        </g>

        <g transform='translate(837 -209.812)'>
          <polygon
            transform='translate(78, 788) rotate(-0 0 0) scale(1.5)'
            points='0,5 0,0 5,2.5'
            stroke='white'
            fill='white'
          />
          <DynamicConnector
            label='34.5KV BESS FEEDER #4'
            pathCoordinates='M0 744.42V792h75.28'
            xCoordinate={183}
            yCoordinate={797}
            className='st8'
          />
        </g>

        <g transform='translate(538.6 -651.307)'>
          <title>Dynamic connector.1006</title>
          <polygon
            transform='translate(5.5, 735) rotate(-90 0 0) scale(1.5)'
            points='0,5 0,0 5,2.5'
            stroke='white'
            fill='white'
          />
          <path d='M8.92 789h.16v-51.58' className='st2' />
        </g>

        <g transform='rotate(90 582.401 757.089)'>
          <title>Sheet.1254</title>
          <path d='M-0 792h45' className='st4' />
        </g>

        <g transform='translate(741.125 -362.583)'>
          <title>Dynamic connector.1269</title>
          <path d='M0 792h-193.64v-95.41' className='st4' />
        </g>

        <g transform='translate(538.489 -485.063)'>
          <title>Dynamic connector.1323</title>
          <path d='M9 792v-60.28' className='st4' />
        </g>

        <g transform='translate(547.489 -457.99)'>
          <title>Dynamic connector.1325</title>
          <path d='M0 780v212.51h193.64' className='st4' />
        </g>
      </g>
    </svg>
  );
}

export default SuperstitionDiagram;
