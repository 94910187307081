import React, { ReactElement } from 'react';
import storeConnector from '@store/storeConnector';
import { SiteMeta } from '@src/types/SiteMeta';
import { useWsSubscribe } from '../../../store/actionCreators/mqtt';
import { Arrow, Switch } from '../../shared/Shared';

interface Section2DevicesProps {
  siteMeta: SiteMeta;
}

function Section2Devices(props: Section2DevicesProps): ReactElement {
  const { siteMeta } = props;
  useWsSubscribe({
    pcs: {
      fields: [],
      sns: siteMeta.UnitsPV,
      cb: () => {},
    },
  });
  return (
    <g>
      <g id='600as1' transform='translate(407 -495.872)'>
        <Switch label='AS-1' status={false} />
      </g>
      <g id='600as2' transform='rotate(180 218 494.873)'>
        <Switch reverse label='AS-2' status={false} />
      </g>
      <g id='600as3' transform='translate(468.5 -495.872)'>
        <Switch label='AS-3' status={false} />
      </g>
      <g id='600as4' transform='rotate(180 248.7 494.873)'>
        <Switch label='AS-4' reverse status={false} />
      </g>
      <g id='pmsg1' transform='translate(407 -410.363)'>
        <Switch label='PMSG-1' status={false} />
      </g>
    </g>
  );
}
const Section2DevicesContainer = storeConnector(Section2Devices, {
  config: ['siteMeta'],
});

function Section2Connectors(): ReactElement {
  return (
    <g>
      <g id='to-nremc1' transform='translate(412.755 -593.605)'>
        <Arrow label='TO NREMC FEEDER' markerId='marker_0' />
      </g>
      <g id='600as3-pmsg1' transform='rotate(-90 -11.471 358.852)'>
        <path d='M0 792h51.25' />
      </g>
      <g id='600as1-600as3' transform='rotate(-90.105 -53.74 316.484)'>
        <path d='M0 792h29.87' />
      </g>
      <g id='600as2-600as4' transform='rotate(-90.105 -23.112 285.912)'>
        <path d='M0 792h29.87' />
      </g>
      <g id='psi-ii10-1' transform='translate(421.677 -540)'>
        <path d='M0 792h61.26' />
      </g>
    </g>
  );
}

function Section2(): ReactElement {
  return (
    <g>
      <Section2DevicesContainer />
      <Section2Connectors />
    </g>
  );
}

export default Section2;
