import { isValidNumber } from '@utils/index_ts';

const isNumberString = (str: unknown): str is string =>
  typeof str === 'string' && /^([0-9]*[.])?[0-9]+$/.test(str);

const roundToTwoNonZero = (num: unknown): number | 'ERR' => {
  let parsedNum: number;
  if (isValidNumber(num)) {
    parsedNum = num;
  } else if (isNumberString(num)) {
    parsedNum = parseFloat(num);
  } else {
    return 'ERR';
  }
  const log10 =
    parsedNum !== 0 && !Number.isNaN(parsedNum)
      ? Math.floor(Math.log10(parsedNum))
      : 0;
  const div = log10 < 0 ? 10 ** (1 - log10) : 100;

  return Math.round(parsedNum * div) / div;
};

const isValueInRange = (
  value: string,
  minimum: number,
  maximum: number,
): boolean =>
  value.length > 0 &&
  parseFloat(value) <= maximum &&
  parseFloat(value) >= minimum;

export { isValueInRange, isNumberString, roundToTwoNonZero };
