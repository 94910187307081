import Spinner from '@components/_elements/Spinner/Spinner';
import PowinBessDetailsModal from '@components/Battery/Powin/PowinBessDetailsModal';
import { useCurrentPowinControllerIdContext } from '@hooks/useCurrentPowinControllerId';
import { isActionRole } from '@src/services/auth';
import { UserRole } from '@src/services/auth/roles';
import { SiteMeta } from '@src/types/SiteMeta';
import { WidgetTypeDeviceList } from '@src/types/Widget';
import storeConnector from '@store/storeConnector';
import React, { useEffect, useMemo, useState } from 'react';
import UIBox from '@components/_shared/UIBox';
import DeviceDataTable, {
  DeviceDataTableRow,
} from '@components/_shared/DeviceDataTable';
import useSubscription from '@hooks/useSubscription';
import useGetWidgetConfig from '@src/hooks/api/queries/useGetWidgetConfig';
import PowinBessCommandsModal from '@components/Battery/Powin/Commands/PowinBess/PowinBessCommandsModal';

interface ListContentProps {
  currentPowinControllerId: string;
  siteMeta: SiteMeta;
  onRowClick: ({ id }: DeviceDataTableRow) => void;
  openCmdModal: (id: string) => void;
  role: UserRole;
}

function ListContent({
  currentPowinControllerId,
  siteMeta,
  onRowClick,
  openCmdModal,
  role,
}: ListContentProps) {
  const deviceNameDictionary = useMemo(
    () =>
      Array.from(new Array(siteMeta.NumPowinBess)).map((_e, i) => ({
        id: `${currentPowinControllerId}_bess_${i + 1}`,
        name: `BESS ${i + 1}`,
      })),
    [currentPowinControllerId, siteMeta],
  );

  const {
    data: widgetConfig,
    isLoading,
    refetch,
  } = useGetWidgetConfig<WidgetTypeDeviceList>('powinBessList', {
    sourceDeviceId: currentPowinControllerId,
  });

  const widgetConfigDatapoints = widgetConfig?.dataPoints
    ? widgetConfig?.dataPoints
    : [];

  useEffect(() => {
    refetch();
  }, [currentPowinControllerId, refetch]);

  const megaDataPointsValues = useSubscription(widgetConfigDatapoints);

  return isLoading ? (
    <Spinner type='content-small' />
  ) : (
    <DeviceDataTable
      enableCmdBtnColumn={
        (siteMeta.ui.Command_Access_Enable ||
          !process.env.VITE_READ_ONLY_MODE) &&
        isActionRole(role)
      }
      hideFooter
      data={megaDataPointsValues}
      disableRowSelectionOnClick
      openCmdModal={openCmdModal}
      onRowClick={onRowClick}
      getRowClassName={() => 'MuiDataGrid-clickable'}
      deviceNameDictionary={deviceNameDictionary}
    />
  );
}

// eslint-disable-next-line max-lines-per-function
function PowinBessList({
  siteMeta,
  role,
}: {
  siteMeta: SiteMeta;
  role: UserRole;
}) {
  const { currentPowinControllerId } = useCurrentPowinControllerIdContext();
  const [cmdModal, setCmdModal] = useState(false);
  const [bessDetailsModal, setBessDetailsModal] = useState(false);
  const [currentBESS, setCurrentBESS] = useState<string>('');

  const powinControllerName = useMemo(
    () =>
      siteMeta.PowinControllerNames[
        siteMeta.PowinControllers.indexOf(currentPowinControllerId)
      ],
    [siteMeta, currentPowinControllerId],
  );

  return (
    <UIBox header={`${powinControllerName} BESS List`}>
      <ListContent
        role={role}
        siteMeta={siteMeta}
        currentPowinControllerId={currentPowinControllerId}
        onRowClick={({ id }: DeviceDataTableRow) => {
          setCurrentBESS(id);
          setBessDetailsModal(true);
        }}
        openCmdModal={(deviceId) => {
          setCurrentBESS(deviceId);
          setCmdModal(true);
        }}
      />
      <PowinBessCommandsModal
        powinControllerName={powinControllerName}
        bessId={currentBESS}
        onClose={() => setCmdModal(false)}
        open={cmdModal}
      />
      <PowinBessDetailsModal
        selectedBessId={currentBESS}
        powinControllerName={powinControllerName}
        open={bessDetailsModal}
        onClose={() => setBessDetailsModal(false)}
      />
    </UIBox>
  );
}

export default storeConnector(PowinBessList, {
  config: ['siteMeta'],
  user: ['role'],
});
