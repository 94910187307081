import NotesButton from '@components/_shared/Notes/NotesButton';
import React, { useState, useEffect } from 'react';
import './UnitTableItem.scoped.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import TextLoader from '@components/_elements/TextLoader';
import { isKeyInObject } from '@utils';
import storeConnector from '@store/storeConnector';
import useIsSunSystemView from '../../../hooks/useIsSunSystemView';
import { faTimes, faWrench } from '@fortawesome/free-solid-svg-icons';
import { isActionRole } from '@src/services/auth';
import { getTopicForTooltip, tsDueTimeMode } from '@utils/index_ts';
import ModeDataCell from './ModeDataCell';

const ROWS_HEIGHT = 40;

const UnitTableItem = ({
  history,
  selected,
  index,
  isShownCommandsPopup,
  siteMeta,
  unitSN,
  timeMode,
  unitName,
  role,
  actions,
  activeHintSN,
  setActiveHintSN,
  topicDict,
  unitsData,
  useIsNoCommsMqttClient,
}) => {
  const isSunSystemView = useIsSunSystemView();

  const racksTotalOverride = siteMeta.Topology_Override.rack?.find(
    (e) => e.unitSN === unitSN,
  );
  const batteryConnectingStatus = {
    racks_online: unitsData.racks_online,
    racks_total:
      racksTotalOverride?.rackCount ||
      Number(siteMeta.NumRacks) / Number(siteMeta.Units.length),
  };
  const [loto, setLoto] = useState({});
  const [isLotoLoading, setIsLotoLoading] = useState(false);

  const getRacksStatus = () => {
    const racksTotal = batteryConnectingStatus.racks_total;
    if (isBatteryLoaded) {
      const racksOnline = batteryConnectingStatus.racks_online;
      return `${racksOnline} / ${racksTotal}`;
    }
    return `0 / ${racksTotal}`;
  };

  const getCircleClass = (unit) => {
    const invStatus = unit['InvStatus'];
    if (['Idle', 'Off'].includes(invStatus)) {
      return '';
    }
    if (invStatus === 'Ready') {
      return '';
    }
    if (
      ['RunPQ', 'RunUF', 'Forming'].includes(invStatus) &&
      unit['RunMode'] === 'Auto' &&
      (+unit['AllowedMaxP'] < +siteMeta.Thresh_UnitMaxP ||
        +unit['AllowedMinP'] > +siteMeta.Thresh_UnitMinP)
    ) {
      return 'blue-a';
    }
    if (
      ['RunPQ', 'RunUF', 'Forming'].includes(invStatus) &&
      unit['RunMode'] === 'Auto'
    ) {
      return 'green-a';
    }
    if (
      ['RunPQ', 'RunUF', 'Forming'].includes(invStatus) &&
      (+unit['AllowedMaxP'] < +siteMeta.Thresh_UnitMaxP ||
        +unit['AllowedMinP'] > +siteMeta.Thresh_UnitMinP)
    ) {
      return 'blue';
    }
    if (['RunPQ', 'RunUF', 'Forming'].includes(invStatus)) {
      return 'green';
    }
    if (
      (['Off', 'Fault', 'PCS Fault', 'BOP Fault'].includes(invStatus) ||
        invStatus?.includes('Fault') ||
        invStatus?.includes('Comms')) &&
      ['Auto', 'Manual'].includes(unit['RunMode'])
    ) {
      return 'red';
    }

    return '';
  };

  const getClass = (value) => {
    const online = +value.split(' / ')[0];
    const all = +value.split(' / ')[1];
    if (online === 0) {
      return 'red-text';
    }
    if (online === all) {
      return 'green-text';
    }
    if (online < all) {
      return 'yellow-text';
    }
  };

  const getCurrentTime = (ts) => {
    return tsDueTimeMode({
      timeMode: timeMode,
      lat: siteMeta.GPSLat,
      long: siteMeta.GPSLong,
      ts,
    });
  };

  const rndrUnitField = (
    field,
    size,
    invStyle,
    placeholder = '-',
    orientation = 'r',
    after,
  ) => {
    if (unitsData[field] === undefined && unitsData[field] === null) {
      return placeholder;
    }
    const topicTooltip = getTopicForTooltip(field, topicDict);

    return (
      <TextLoader
        orientation={orientation}
        fontSize={size}
        fractionDigits={2}
        loading={isKeyInObject(unitsData, field)}
        invertedCcolor={invStyle}
        after={after}
        field={field}
        toolTipContent={topicTooltip}
      >
        {typeof unitsData[field] === 'number'
          ? unitsData[field].toFixed(2)
          : unitsData[field]}
      </TextLoader>
    );
  };

  useEffect(() => {
    setIsLotoLoading(true);
    (async () => {
      await actions.getLOTO(unitSN).then((res) => {
        if (res.Mode === 'lockout') {
          setLoto(res);
        } else {
          setLoto({});
        }
        setIsLotoLoading(false);
      });
    })();
  }, [isShownCommandsPopup, unitSN]);

  const renderLotoField = (field, size) => {
    const topicTooltip = getTopicForTooltip(field, topicDict);
    return (
      <TextLoader
        fontSize={size}
        loading={isLotoLoading}
        toolTipContent={topicTooltip}
      >
        {loto[field]}
      </TextLoader>
    );
  };

  const handleUnitIdClick = () => {
    actions.setActiveUnitSN(unitSN);
    actions.setActiveUnitName(unitName);
    const splitPath = history.location.pathname
      .split('/')
      .filter((str) => !!str);
    const tabName = splitPath[splitPath.length - 1];
    if (tabName === 'sys') {
      history.push(
        `/${splitPath.slice(0, splitPath.length - 1).join('/')}/pcs`,
      );
    }
  };

  const isBatteryLoaded = batteryConnectingStatus.racks_online !== null;
  const lotoMode = loto?.Mode === 'lockout';

  return (
    <React.Fragment key={unitSN}>
      <tr
        className={`unit-table-row ${selected ? 'selected' : ''}`}
        style={{ height: `${ROWS_HEIGHT}px` }}
      >
        <td>
          <div
            className='cell-with-cursor-pointer unit-name-cell text-center'
            onClick={() => handleUnitIdClick()}
          >
            {siteMeta.UnitNames[index]}
          </div>
        </td>

        {isActionRole(role) && siteMeta.ui.Commands_Enable ? (
          <td>
            <div style={{ display: 'flex', verticalAlign: 'middle' }}>
              <FontAwesomeIcon
                className='cell-with-cursor-pointer'
                icon={faWrench}
                size={'lg'}
                onClick={() =>
                  actions.showComandsPopup(true, {
                    popupType: 'unit',
                    sn: unitSN,
                    lotoTableRt: loto,
                  })
                }
              />
            </div>
          </td>
        ) : null}

        {isActionRole(role) && siteMeta.ui.Notes_Enable ? (
          <td>
            <div style={{ display: 'flex', verticalAlign: 'middle' }}>
              <NotesButton
                className='cell-with-cursor-pointer'
                size={'lg'}
                deviceId={unitSN}
              />
            </div>
          </td>
        ) : null}

        <ModeDataCell
          lotoMode={lotoMode}
          allowedMaxP={rndrUnitField('AllowedMaxP', 12, true)}
          allowedMaxQ={rndrUnitField('AllowedMaxQ', 12, true)}
          allowedMinP={rndrUnitField('AllowedMinP', 12, true)}
          allowedMinQ={rndrUnitField('AllowedMinQ', 12, true)}
          pcsP={rndrUnitField('PCS_P', 12, true)}
          pcsQ={rndrUnitField('PCS_Q', 12, true)}
          invStatus={rndrUnitField('InvStatus', 12, true)}
          runMode={rndrUnitField('RunMode', 12, true)}
          setActiveHintSN={setActiveHintSN}
          circleClass={getCircleClass(unitsData)}
          unitSN={unitSN}
          siteMeta={siteMeta}
          useIsNoCommsMqttClient={useIsNoCommsMqttClient}
        />
        <td>{rndrUnitField('kW', 13, false, '-', 'c')}</td>
        <td>{rndrUnitField('kVAR', 13, false, '-', 'c')}</td>
        {!isSunSystemView && (
          <>
            <td>{rndrUnitField('SOC', 13, false, '-', 'c', '%')}</td>
            <td className={getClass(getRacksStatus())}>
              <TextLoader
                orientation='c'
                fontSize={13}
                loading={!isBatteryLoaded}
                expectedSize={5}
                toolTipContent={getTopicForTooltip('racks_online', topicDict)}
              >
                {getRacksStatus()}
              </TextLoader>
            </td>
          </>
        )}
      </tr>
      {lotoMode && activeHintSN && activeHintSN === unitSN ? (
        <tr className='unit-table-row'>
          <td colSpan='8'>
            <div className='loto-hint'>
              <div className='flex-row-c'>
                <span>
                  <span>TS:</span> {getCurrentTime(loto.TS)}
                </span>
                <span className='flex-row-c'>
                  <span>Operator:</span>&nbsp;{renderLotoField('Operator', 13)}
                </span>
                <span className='flex-row-c'>
                  <span>Notes:</span>&nbsp;{renderLotoField('Note', 13)}
                </span>
              </div>
              <div onClick={() => setActiveHintSN(null)}>
                <FontAwesomeIcon icon={faTimes} />
              </div>
            </div>
          </td>
        </tr>
      ) : (
        <></>
      )}
    </React.Fragment>
  );
};

export default storeConnector(UnitTableItem, {
  user: ['role'],
  config: ['siteMeta'],
  service: ['isShownCommandsPopup', 'history', 'timeMode'],
  mqtt: ['topicDict'],
});
