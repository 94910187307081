import BreakerCommandsDialog from '@src/sld/shared/Breaker/BreakerCommandsDialog';
import { GRAY, GREEN, RED } from '@src/sld/shared/Shared';
import storeConnector from '@src/store/storeConnector';
import { SiteMeta } from '@src/types/SiteMeta';
import React, { ReactElement, useState } from 'react';

interface BreakerProps {
  label: string;
  status: string | number | null | undefined;
  enableBreakerCommands?: boolean;
  yCoordinate: number;
  xCoordinate: number;
  siteMeta: SiteMeta;
}

function Breaker({
  status,
  label,
  xCoordinate,
  yCoordinate,
  enableBreakerCommands,
  siteMeta,
}: BreakerProps): ReactElement {
  const [showBreakerCmdDialog, setShowBreakerCmdDialog] = useState(false);

  const setFillColor = (v: string | number | null | undefined) => {
    if (v === null || v === undefined) return GRAY;
    return v !== 0 ? RED : GREEN;
  };

  const breakerTextProps = {
    fill: 'currentColor',
    stroke: 'none',
    textAnchor: 'middle',
    transform: '',
    x: xCoordinate,
    y: yCoordinate,
  };

  const breakerProps = {
    ...(enableBreakerCommands &&
    siteMeta.ui.Command_Access_Enable &&
    !process.env.VITE_READ_ONLY_MODE
      ? {
          style: { cursor: 'hand' },
          onClick: () => setShowBreakerCmdDialog(true),
        }
      : {}),
  };

  return (
    <>
      <g {...breakerProps}>
        <text {...breakerTextProps}>{label}</text>
        <rect
          y='765'
          width='27'
          height='27'
          className='st5'
          fill={setFillColor(status)}
        />
      </g>

      {showBreakerCmdDialog && (
        <BreakerCommandsDialog
          breakerName={label}
          setShowBreakerCmdDialog={setShowBreakerCmdDialog}
        />
      )}
    </>
  );
}

export default storeConnector(Breaker, {
  config: ['siteMeta'],
});
