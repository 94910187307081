import React, { useRef, useState } from 'react';
import './SiteUnitCommandsPopup.scoped.scss';
import Button from '@components/_elements/Button/Button';
import storeConnector from '@store/storeConnector';

const PowerCommandForm = ({
  popupProps,
  Qset,
  Pset,
  showConfPopup,
  allowedMinReal,
  allowedMaxReal,
  allowedMinReactive,
  allowedMaxReactive,
  setOverInput,
}) => {
  const [reactive, setReactive] = useState(+Qset || 0);
  const [real, setReal] = useState(+Pset || 0);
  const inputFirstRef = useRef();
  const inputSecondRef = useRef();

  const onEnter = (key) => {
    if (key === 0) {
      inputSecondRef.current.focus();
    }
    if (key === 1) {
      if (
        real !== '' &&
        reactive !== '' &&
        real > allowedMinReal &&
        real < allowedMaxReal &&
        reactive > allowedMinReactive &&
        reactive < allowedMaxReactive
      ) {
        showConfPopup(`Power::${real}::${reactive}`, 'value');
      } else {
        if (real !== '' && real > allowedMinReal && real < allowedMaxReal) {
          inputSecondRef.current.focus();
        } else {
          inputFirstRef.current.focus();
        }
      }
    }
  };

  return (
    <>
      <div className='popup-row'>
        <div className='bold vertical-margin'>
          {popupProps.popupType === 'unit' ? 'Unit Power Command' : 'Power'}
        </div>
        <div className='popup-table'>
          <div className='popup-table-col'>
            <div>Real (P):</div>
            <div className='range-lable' />
            <div>Reactive (Q):</div>
            <div className='range-lable' />
          </div>
          <div className='popup-table-col'>
            <div>
              <input
                ref={inputFirstRef}
                onKeyDown={(e) => {
                  if (e.keyCode === 13) {
                    onEnter(0);
                    e.preventDefault();
                  }
                }}
                className={
                  'comand-popup-inp ' +
                  (real < allowedMinReal
                    ? 'error'
                    : real > allowedMaxReal
                      ? 'error'
                      : '')
                }
                type='number'
                step='0.1'
                value={real}
                onMouseEnter={() => setOverInput(true)}
                onMouseLeave={() => setOverInput(false)}
                onChange={(e) => setReal(e.target.value)}
              />
            </div>
            <div className='range-lable'>
              Range from {allowedMinReal.toFixed(2)} to{' '}
              {allowedMaxReal.toFixed(2)}
            </div>
            <div>
              <input
                ref={inputSecondRef}
                onKeyDown={(e) => {
                  if (e.keyCode === 13) {
                    onEnter(1);
                    e.preventDefault();
                  }
                }}
                className={
                  'comand-popup-inp ' +
                  (reactive < allowedMinReactive.toFixed(2)
                    ? 'error'
                    : reactive > allowedMaxReactive.toFixed(2)
                      ? 'error'
                      : '')
                }
                type='number'
                step='0.1'
                value={reactive}
                onMouseEnter={() => setOverInput(true)}
                onMouseLeave={() => setOverInput(false)}
                onChange={(e) => setReactive(e.target.value)}
              />
            </div>
            <div className='range-lable'>
              Range from {allowedMinReactive.toFixed(2)} to&nbsp;
              {allowedMaxReactive.toFixed(2)}
            </div>
          </div>
          <div className='popup-table-col'>
            <div>kW</div>
            <div className='range-lable' />
            <div>kvar</div>
            <div className='range-lable' />
          </div>
        </div>
      </div>
      {(real < allowedMinReal || real > allowedMaxReal) && (
        <div className='popup-row warn-alert'>
          The value of real power must be between {allowedMinReal} and{' '}
          {allowedMaxReal}
        </div>
      )}
      {(reactive < allowedMinReactive || reactive > allowedMaxReactive) && (
        <div className='popup-row warn-alert'>
          The value of reactive power must be between {allowedMinReactive} and{' '}
          {allowedMaxReactive}
        </div>
      )}
      <div className='popup-row commands-popup-control'>
        <Button
          size='m'
          disabled={
            real === '' ||
            reactive === '' ||
            real < allowedMinReal ||
            real > allowedMaxReal ||
            reactive < allowedMinReactive ||
            reactive > allowedMaxReactive
          }
          onClick={() => showConfPopup(`Power::${real}::${reactive}`, 'value')}
        >
          Send
        </Button>
      </div>
    </>
  );
};

export default storeConnector(PowerCommandForm, {
  service: ['popupProps'],
});
