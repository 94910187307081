import React, { ReactElement, useState } from 'react';
import storeConnector from '@store/storeConnector';
import { SiteMeta } from '@src/types/SiteMeta';
import { Module, DcBus, Transformer, DcBusData } from '../../shared/Shared';
import { useWsSubscribe } from '../../../store/actionCreators/mqtt';

interface Section1DevicesProps {
  siteMeta: SiteMeta;
}
function Section3Devices(props: Section1DevicesProps): ReactElement {
  const { siteMeta } = props;
  const [dcBusData, setDcBusData] = useState<DcBusData>({});
  useWsSubscribe({
    pcs: {
      fields: ['DCkW', 'Hz', 'PF', 'DCV', 'DCA', 'DCDisconnect'],
      sns: [siteMeta.UnitsPV[1]],
      cb: (incoming: DcBusData) =>
        setDcBusData((prev) => ({ ...prev, ...incoming })),
    },
  });
  return (
    <g>
      <g id='inv2-mod1' transform='translate(722.477 -223.062)'>
        <Module label='INV 2 - MOD 1' status={dcBusData.DCDisconnect} />
      </g>
      <g id='inv2-pcs1' transform='translate(707.045 -97.697)'>
        <DcBus data={dcBusData} />
      </g>
      <g id='inv2-xfmr2' transform='rotate(90 577.555 987.515)'>
        <Transformer label='INV 2 - XFMR' />
      </g>
    </g>
  );
}

const Section3DevicesContainer = storeConnector(Section3Devices, {
  config: ['siteMeta'],
});
function Section3Connectors(): ReactElement {
  return (
    <g>
      <g id='xfmr2-psmg2' transform='rotate(90 600.377 981.133)'>
        <path d='M0 792h37.43' />
      </g>
      <g id='inv2-mod1-xfmr2' transform='translate(735.821 -249.75)'>
        <path d='M0 792v-55.46h53.69v-52.17' />
      </g>
      <g id='inv2-mod1-foreignobject1' transform='translate(726.821 -223.062)'>
        <path d='M9 792v67.81' />
      </g>
    </g>
  );
}
function Section3(): ReactElement {
  return (
    <g>
      <Section3DevicesContainer />
      <Section3Connectors />
    </g>
  );
}
export default Section3;
